'use client'

import { useState } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { alpha } from '@mui/material/styles'
import { Grid, IconButton, Tooltip, Avatar, Menu } from '@mui/material'
import { LaunchTwoTone as LaunchTwoToneIcon } from '@mui/icons-material'

// Types
import type { MouseEvent } from 'react'
import type { AvatarBarProps } from '@/types/components/molecules/avatar-bar'

// Configurations
import Config from '@/config'

// Helpers
import { useUserStore } from '@/helpers/store/user'
import { useTranslation } from '@/helpers/i18n/client'

// Components
const SettingsModule = dynamic(() => import('@/components/molecules/settings'))

const AvatarBarMolecule = (props: AvatarBarProps) => {
	// Props
	const { lng } = props

	// Varaibles
	const { t } = useTranslation(lng)
	const { name, picture } = useUserStore()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	return (
		<Grid size="auto">
			{Config.siteURL && (
				<Link href={Config.siteURL} target="_blank">
					<IconButton>
						<LaunchTwoToneIcon />
					</IconButton>
				</Link>
			)}

			<Tooltip title={t('common:link.openSettings')}>
				<IconButton
					aria-label="account of current user"
					aria-controls="avatar-menu"
					aria-haspopup="true"
					onClick={(event: MouseEvent<HTMLElement>) => {
						setAnchorEl(event.currentTarget)
					}}
					color="inherit"
					sx={{ ml: 1, p: 0.5 }}
				>
					<Avatar alt={name ?? undefined} src={picture ?? undefined} sx={{ width: 32, height: 32 }} />
				</IconButton>
			</Tooltip>

			<Menu
				keepMounted
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				slotProps={{
					paper: { variant: 'outlined' }
				}}
				onClose={() => {
					setAnchorEl(null)
				}}
				sx={theme => ({
					'& .MuiPaper-root': {
						width: 250,
						bgcolor: alpha(theme.palette.background.paper, 0.7),
						backdropFilter: 'blur(5px)'
					}
				})}
			>
				<SettingsModule lng={lng} />
			</Menu>
		</Grid>
	)
}

export default AvatarBarMolecule
